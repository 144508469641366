<template>
  <section class="contact-form">
    <div class="contact-container">
    <img class="pattern-header" src="../assets/pattern.png" alt="Design">
    <img class="full-pattern" src="../assets/full-pattern.png" alt="Design">
    <div class="left-column">
      <h1>¿Te ha quedado alguna duda?</h1>
      <p>¡Contacta con JJP, tu personal shopper inmobiliario para inversores! <br><br>Si eres inversor y buscas asesoramiento para la compra de inmuebles que te generen altas rentabilidades somos tu partner. <br><br><b>¡Contáctanos!</b></p>
      <div class="buttons-header">
        <p class="position"><b>CEO's</b></p>
        <div class="infoContact"><a :href="'mailto:jpablo.molina@jjpinvestments.com'"><i class="fa-solid fa-envelope"></i>jpablo.molina@jjpinvestments.com</a></div>
        <div class="infoContact"><a :href="'mailto:jorge.claramunt@jjpinvestments.com'"><i class="fa-solid fa-envelope"></i>jorge.claramunt@jjpinvestments.com</a></div>
      </div>
      <div class="buttons-header">
        <p class="position"><b>Socia</b></p>
        <div class="infoContact"><a :href="'mailto:ester.guerra@jjpinvestments.com'"><i class="fa-solid fa-envelope"></i>ester.guerra@jjpinvestments.com</a></div>
        <div class="infoContact"><a href="#"><i class="fa-solid fa-phone"></i>+34 666 807 004</a></div>
      </div>
    </div>
    <div class="right-column">
      <form ref="myForm" class="contact" @submit.prevent="sendEmail">
        <h3>Formulario de contacto</h3>
        <div class="form-group">
          <label for="name">Nombre*</label>
          <input type="text" id="name" v-model="name" name="name" required />
        </div>
        <div class="form-group">
          <label for="name">Apellidos*</label>
          <input type="text" id="surname" v-model="surname" name="surname" required />
        </div>
        <div class="form-group">
          <label for="email">Email*</label>
          <input type="email" id="email" v-model="email" name="email" required/>
        </div>
        <div class="form-group">
          <label for="phone">Teléfono*</label>
          <input type="tel" id="phone" v-model="phone" name="phone" required maxlength="16"/>
        </div>
        <div class="form-group">
          <label for="message">¿Qué necesitas?*</label>
          <textarea id="message" v-model="message" name="message" required></textarea>
        </div>
        <label for="checkboxId">
          <div class="checkbox">
            <input class="checkbox-input" type="checkbox" id="check1" name="checkboxName" required>
            <p class="checkbox-label">De acuerdo con lo anterior autorizo a JJP Investments a utilizar mis datos personales para llevar a cabo las gestiones necesarias para el asesoramiento en la compra de bienes inmuebles.</p>
          </div>
        </label>
        <label for="checkboxId">
          <div class="checkbox">
            <input class="checkbox-input" type="checkbox" id="check2" name="checkboxName" required>
            <p class="checkbox-label">Autorizo a JJP Investments a compartir mis datos de contacto con terceros para llevar a cabo las gestiones necesarias para el asesoramiento en la compra de bienes inmuebles.</p>
          </div>
        </label>
        <button class="button" type="submit">Enviar formulario</button>
        <!-- Mensaje de confirmación -->
        <div v-if="showConfirmationMessage" class="confirmation-message">
          ¡Muchas gracias por confiar en nosotros, nos pondremos en contacto contigo!
        </div>
      </form>
    </div></div>
  </section>
</template>

<script>

import emailjs from '@emailjs/browser';

export default {
name: 'ContactForm',
data() {
  return {
    name: '',
    surname: '',
    email: '',
    phone: '',
    message: '',
    showConfirmationMessage: false,
  }
},
mounted() {
  window.addEventListener('scroll', this.handleScroll);
},
methods: {
  handleScroll() {
    let sections = document.querySelectorAll('.animation');
    sections.forEach(section => {
      let position = section.getBoundingClientRect();
      if (position.top < window.innerHeight && position.bottom >= 0) {
        section.classList.add('active');
      } else {
        section.classList.remove('active');
      }
    });
  },
  toggleAnswer(index) {
    this.faqItems[index].open = !this.faqItems[index].open;
  },
  sendEmail() {
      emailjs.sendForm('service_qb4kvg9', 'template_8zfymds', this.$refs.myForm, 'TPetO5xYCK8eTNonC')
        .then((result) => {
            console.log('SUCCESS', result.text);
            // Restablecer los valores de los campos del formulario
            this.name = '';
            this.surname = '';
            this.email = '';
            this.phone = '';
            this.message = '';
            // Mostrar el mensaje de confirmación
            this.showConfirmationMessage = true;
        }, (error) => {
            console.log('FAILED', error.text);
        });
    }
  },
};
</script>

<style scoped>

h1 {
  color: #3B3A5D;
  font-size: 45px;
  text-align: start;
  padding: 0;
  margin: 0;
  font-family: 'Kufam';
}

h2 {
  color: #3B3A5D;
  font-size: 35px;
  margin: 0;
  padding: 0;
  margin-bottom: 4rem;
  font-family: 'Kufam';
}

h3 {
  color: #3B3A5D;
  font-size: 25px;
  margin: 0;
  padding: 0;
  margin-bottom: 2rem;
  font-family: 'Kufam';
}

p {
  font-size: 18px;
  color: #B16642;
  font-family: 'Kufam';
  padding: 0;
  margin: 0;
}

a {
  color: #B16642;
  font-family: 'Kufam';
}

i {
  margin-right: .5rem;
}

label {
  color: #3B3A5D;
  font-size: 14px;
  margin-bottom: 5px;
  font-family: 'Kufam';
}

input {
  background-color: #F3F3F3;
  border-radius: 5px;
  border: 0;
  outline: 2px solid #F3F3F3;
}

textarea {
  background-color: #F3F3F3;
  border-radius: 5px;
  min-height: 100px;
  border: 0;
  resize: none;
  outline: 2px solid #F3F3F3;
}

.position {
  font-size: 15px;
}

.contact-container {
  background: linear-gradient(to right, rgba(164,131,115, .2), #FAA14F);
  text-align: start;
  display: flex;
  justify-content: space-between;
  padding-bottom: 4rem;
  padding-right: 13rem;
  padding-left: 13rem;
  margin-bottom: 4rem;
  padding-top: 11rem;
}

.button {
  background-color: #3B3A5E;
  color: white;
  margin-top: 1rem;
  padding: 15px;
  padding-right: 30px;
  padding-left: 30px;
  border-radius: 5px;
  transition: transform 0.3s ease; 
  font-family: 'Kufam';
}

.button:hover {
  transition: transform 0.3s ease; 
  transform: scale(1.1); 
}

.confirmation-message {
  background-color: #3B3A5D;
  padding: 10px;
  border-radius: 5px;
  color: white;
  margin-top: 15px;
}

.left-column {
  flex: 1;
  margin-right: 3rem;
  z-index: 4;
}

.right-column {
  margin-left: 3rem;
  flex: 1;
  z-index: 4;
}

.buttons-header {
  display: flex;
  flex-direction: column;
  width: auto;
  margin-top: 1rem;
}

.infoContact {
  background-color: white;
  border-radius: 5px;
  width: auto;
  padding: 10px;
  display: inline-block;
  margin-bottom: 10px;
  width: fit-content;
}

.contact {
  background-color: white;
  padding: 2rem;
  padding-right: 3rem;
  margin: 0;
  border-radius: 5px;
}

.form-group {
  margin-bottom: 1rem;
}

.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkbox-input {
  margin: 0;
  margin-right: 1rem;
  padding: 0;
  width: 15px;
  outline: 0;
}

.checkbox-label {
  font-size: 12px;
  color: #3B3A5D;
  margin: 0;
  padding: 0;
  font-weight: 400;
}

label {
  display: block;
  font-weight: 500;
}

input,
textarea {
  width: 100%;
  padding: 0.5rem;
}

button {
  background-color: #333;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.faq-item {
  margin-bottom: 2rem;
}

.faqs {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 80%; */
}

.question {
  color: #3B3A5D;
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: 500;
  font-family: 'Kufam';
  display: flex;
  width: 700px;
  align-items: center;
  justify-content: space-between;
}

.question-text {
  margin-left: 10px; /* Ajusta la separación entre el icono y el texto de la pregunta */
  text-align: left;
}

.answer {
  color: #7D7D7D;
  font-size: 16px;
  margin-bottom: 5px;
  font-family: 'Kufam';
  /* text-align: left; */
  max-width: 700px; /* Aplica el mismo ancho máximo para respuestas abiertas y cerradas */
  white-space: nowrap;
  overflow: hidden;
  transition: max-width 0.3s ease-in-out;
}

.open-answer {
  max-width: 700px; 
  text-align: left;
  white-space: normal;
  overflow: hidden;
  transition: max-width 0.3s ease-in-out;
}

.closed-answer {
  max-width: 700px; /* Usa el mismo valor de ancho máximo que en .answer y .open-answer */
  white-space: nowrap;
  overflow: hidden;
  transition: max-width 0.3s ease-in-out;
}

.pattern {
  width: 200px;
  position: absolute;
  left: 0;
  height: auto;
  margin: 0;
  padding: 0;
}

.pattern-header {
  width: 300px;
  position: absolute;
  left: 0;
  height: auto;
  margin: 0;
  padding: 0;
}

.full-pattern {
  width: 400px;
  position: absolute;
  right: 0;
  height: auto;
  margin: 0;
  padding: 0;
}

.arrow {
  align-items: center;
  background-color: #FFBB50;
  color: #3B3A5D;
  padding: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 20px;
}

.button-question {
  font-family: 'Kufam';
  color: #3B3A5D;
  padding: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;  
}

.animation {
  opacity: 0;
  transform: translateY(60px);
  transition: opacity 0.8s, transform 0.8s;
}

.animation.active {
  opacity: 1;
  transform: translateY(0);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.line {
  height: 1px;
  background-color: #F4D5C6;
  margin: 10px 0;
}

@media screen and (min-width: 1300px) and (max-width: 1400px) {
  .contact-container {
    padding-right: 10rem;
    padding-left: 10rem;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .contact-container {
    padding-right: 8rem;
    padding-left: 8rem;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1200px) {
  .contact-container {
    padding-right: 6rem;
    padding-left: 6rem;
  }
}

@media screen and (min-width: 1029px) and (max-width: 1100px) {
  .contact-container {
    padding-right: 4rem;
    padding-left: 4rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1028px) {
  h1 {
    font-size: 38px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 22px;
  }

  p {
    font-size: 16px;
  }

  .question-text {
    font-size: 18px;
  }

  .contact-container {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .left-column {
    margin-right: 1rem;
  }

  .right-column {
    margin-left: 1rem;
  }
  
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 25px;
  }

  h3 {
    font-size: 20px;
  }

  p {
    font-size: 16px;
  }

  .contact-container {
    flex-direction: column;
    padding-right: 2rem;
    padding-left: 2rem;
  }
  
  .buttons-header {
    margin-bottom: 1rem;
  }

  .infoContact {
    margin-right: 1rem;
  }

  .left-column,
  .right-column {
    margin: 0;
  }

  .faq-item {
    width: 95%; /* Hace que las preguntas ocupen el ancho completo */
  }

  .question {
    width: 95%; /* Ajusta el ancho de las preguntas */
  }

  .question-text {
    font-size: 18px;
  }

  .arrow {
    width: 25px;
    height: 25px;
  }

  .contact-container {
    flex-direction: column;
    padding-right: 2rem;
    padding-left: 2rem;
  }
  
  .buttons-header {
    margin-bottom: 1rem;
  }

  .infoContact {
    margin-right: 1rem;
  }

  .left-column,
  .right-column {
    margin: 0;
  }
}

@media screen and (min-width: 320px) and (max-width: 481px) {
  h1 {
    font-size: 25px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 18px;
  }

  a {
    font-size: 14px;
  }

  p {
    font-size: 16px;
  }

  label {
    font-size: 14px;
  }

  .contact-container {
    flex-direction: column;
    padding-right: 2rem;
    padding-left: 2rem;
  }
  
  .buttons-header {
    margin-bottom: 1rem;
  }

  .infoContact {
    margin-right: 1rem;
  }

  .left-column,
  .right-column {
    margin: 0;
  }

  .question-text {
    font-size: 18px;
  }

  .arrow {
    width: 20px;
    height: 20px;
  }

  .contact {
    padding: 1rem;
    padding-right: 2rem;
  }

}

</style>

