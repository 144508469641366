<template>
  <div id="app">
    <AppHeader />
    <div class="content-container">
      <router-view />
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue';
import AppFooter from './components/AppFooter.vue';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ocupa al menos el 100% del viewport vertical */
  justify-content: space-between;
}

.content-container {
  width: 100%; /* Define el ancho del 100% */
  margin: 0 auto; /* Centra el contenido horizontalmente */
  padding: 0;
}

body, html {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

</style>
