// router.js
import Vue from 'vue';
import VueRouter from 'vue-router';
import ContactForm from './components/ContactForm.vue';
import PrivacyPolicy from './components/PrivacyPolicy.vue';
import HomeApp from './components/HomeApp.vue';
import PropertyDetail from './components/PropertyDetail.vue';
import Construction from './components/Construction.vue';
import LegalPage from './components/LegalPage.vue';

Vue.use(VueRouter);

const routes = [
   { path: '/', name: 'Home', component: HomeApp },
   { path: '/', name: 'About us', component: HomeApp },
   { path: '/', name: 'Propierties', component: HomeApp },
   { path: '/', name: 'Locations', component: HomeApp },
   { path: '/ContactForm', name: 'Contact us', component: ContactForm },
   { path: '/property/:id', name: 'PropertyDetail', component: PropertyDetail, props: true },
   { path: '/PrivacyPolicy', name: 'Privacy Policy', component: PrivacyPolicy },
   { path: '/Legal', name: 'Legal', component: LegalPage },
  { path: '/', name: 'Home', component: Construction },
];

const router = new VueRouter({
  routes,
  mode: 'history', // Opcional, para eliminar el signo # de las URLs
  base: process.env.BASE_URL,
});

export default router;
