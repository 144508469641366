<template>
  <div>
    <img class="image-header" :src="property.image" :alt="property.title">
    <div class="container-info">
      <div class="container-rentabilidad">
        <h3>{{ property.title }}</h3>
        <div>
          <p class="rentabilidad-number">{{ property.rentabilidad }} %</p>
          <p class="rentabilidad-text">Rentabilidad</p>
        </div>
      </div>
      <div class="container-details">
        <div class="detail">
          <p class="price_header">{{ property.zona }}</p>
          <p class="type_header">Zona</p>
        </div>
        <div class="detail">
          <p class="price_header">{{ property.alquiler }}</p>
          <p class="type_header">Alquiler</p>
        </div>
      </div>
      <div class="line"></div>
      <h4>Detalles de la inversión</h4>
      <div class="containerInfo">
        <div class="detail">
          <p class="price">{{ property.total }}</p>
          <p class="type">Inversión total</p>
        </div>
        <div class="detail">
          <p class="price">{{ property.rentabilidad }}%</p>
          <p class="type">Rentabilidad</p>
        </div>
        <div class="detail">
          <p class="price">{{ property.mensual }}</p>  
          <p class="type">Alquiler mensual</p>
        </div>
        <div class="detail">
          <p class="price">{{ property.superficie }}</p>
          <p class="type">Superficie</p>
        </div>
        <div class="detail">
          <p class="price">{{ property.habitaciones }}</p>
          <p class="type">Habitaciones</p>
        </div>
        <div class="detail">
          <p class="price">{{ property.baños }}</p>
          <p class="type">Baños</p>
        </div>
      </div>

      <div>
        <h4>Galería</h4>  
        <div class="carousel-container">
          <carousel :perPage="perPage" :autoplay="true" :loop="true">
            <slide v-for="(image, index) in property.images" :key="index">
              <img class="imageCarrusel" :src="image" :alt="'Slide ' + (index + 1)" />
            </slide>
          </carousel>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: 'PropertyDetail',
  props: {
    id: String,
    property: Object,
  },
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      modalOpen: false,
      modalImage: '',
      perPage: 4,
    };
  },
  mounted() {
    if (window.innerWidth < 481) {
      this.perPage = 1;
    } else if (window.innerWidth > 481 && window.innerWidth < 768) {
      this.perPage = 2;
    } else {
      this.perPage = 3;
    }
  }
};
</script>

<style scoped>
  h3 {
    color: #3B3A5D;
    font-size: 30px;
    text-align: start;
    padding: 0;
    margin: 0;
    margin-bottom: 1rem;
    font-family: 'Kufam';
  }

  h4 {
    color: #3B3A5D;
    font-size: 25px;
    text-align: start;
    padding: 0;
    margin: 0;
    margin-bottom: 1rem;
    font-family: 'Kufam';
  }

  .image-header {
    width: 100%;
    background-size: cover;
    object-fit: cover;
    background-position: center;
    border: none;
    height: 75vh;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }

  .container-info {
    margin-right: 13rem;
    margin-left: 13rem;
    margin-top: 2rem;
  }

  .container-rentabilidad {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }

  .rentabilidad-number {
    color: #FFBB50;
    margin: 0;
    padding: 0;
    text-align: right;
    font-weight: 600;
    font-size: 25px;
    font-family: 'Kufam';
  }

  .rentabilidad-text {
    color: #FFBB50;
    margin: 0;
    padding: 0;
    text-align: right;
    font-weight: 600;
    font-size: 14px;
    font-family: 'Kufam';
  }

  .container-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
  }

  .detail {
    margin-right: 2rem;
    box-sizing: border-box;
  }

  .line {
    height: 1px;
    background-color: rgba(141,141,141,0.3);
    margin: 10px 0;
    margin-bottom: 3rem;
  }

  .containerInfo {
    border: 1px solid rgba(141,141,141,0.3);
    border-radius: 5px;
    align-self: center;
    align-items: center;
    justify-content: space-between;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 3rem;
    padding: 2rem;
    flex: wrap;
  }

  .price {
    font-size: 20px;
    color: #3B3A5D;
    font-weight: 600;
    text-align: left;
    margin: 0;
    margin-top: 1rem;
    padding: 0;
    font-family: 'Kufam';
  }

  .price_header {
    font-size: 20px;
    color: #3B3A5D;
    font-weight: 600;
    text-align: left;
    margin: 0;
    margin-top: 1rem;
    padding: 0;
    font-family: 'Kufam';
  }

  .type {
    font-size: 14px;
    color: #8D8D8D;
    text-align: left;
    margin: 0;
    padding: 0;
    font-family: 'Kufam';
    /* width: 80%; */
  }

  .type_header {
    font-size: 14px;
    color: #8D8D8D;
    text-align: left;
    margin: 0;
    padding: 0;
    font-family: 'Kufam';
  }

  .carousel-container {
    overflow-x: auto;
    white-space: nowrap;
    margin-bottom: 3rem;
    width: 100%;
  }

  .imageCarrusel {
    object-fit: cover;
    width: 95%;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    height: 250px;
    display: inline-block;
  }

  .modal {
    position: fixed;
    z-index: 9;
    padding-top: 50px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.9);
  }

  .modal-content {
    margin: auto;
    display: block;
    max-width: 90%;
    max-height: 90%;
  }

  .close {
    color: white;
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 30px;
    cursor: pointer;
  }

  /* Mostrar el modal */
  .modal.active {
    display: block;
  }


  @media screen and (min-width: 1300px) and (max-width: 1400px) {
    .container-info {
      margin-right: 10rem;
      margin-left: 10rem;
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1300px) {
    .container-info {
      margin-right: 8rem;
      margin-left: 8rem;
    }
  }

  @media screen and (min-width: 1100px) and (max-width: 1200px) {
    .container-info {
      margin-right: 6rem;
      margin-left: 6rem;
    }
  }

  @media screen and (min-width: 1029px) and (max-width: 1100px) {
    .container-info {
      margin-right: 4rem;
      margin-left: 4rem;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1028px) {

    h3 {
      font-size: 28px;
    }

    h4 {
      font-size: 22px;
    }

    .image-header {
      height: 60vh;
    }

    .container-info {
      margin-right: 2rem;
      margin-left: 2rem;
    }

    .rentabilidad-number {
      text-align: left; 
      font-size: 22px;
    }

    .rentabilidad-text {
      text-align: left; /* Alinear el texto a la izquierda en pantallas más pequeñas */
      font-size: 14px; 
    }

    .containerInfo {
      display: flex;
      flex-wrap: wrap; /* Permitir que los elementos se envuelvan en filas adicionales */
    }

    .detail {
      margin-right: .5rem; /* Añadir un pequeño espacio entre los elementos */
    }

    .carousel-container {
      margin-right: 1rem;
      margin-left: 1rem;
    }

  }

  @media screen and (min-width: 481px) and (max-width: 769px) {

    h3 {
      font-size: 25px;
    }

    h4 {
      font-size: 20px;
    }

    .image-header {
      height: 50vh;
    }

    .container-info {
      margin-right: 1rem;
      margin-left: 1rem;
    }

    .rentabilidad-number {
      text-align: left; 
      font-size: 20px;
    }
    .rentabilidad-text {
      text-align: left; /* Alinear el texto a la izquierda en pantallas más pequeñas */
      font-size: 14px; 
    }

    .price {
      font-size: 18px;
    }

    .price_header {
      font-size: 18px;
    }

    .containerInfo {
      display: flex;
      flex-wrap: wrap; /* Permitir que los elementos se envuelvan en filas adicionales */
    }

    .detail {
      margin-right: .5rem; /* Añadir un pequeño espacio entre los elementos */
    }

    .carousel-container {
      margin-right: 1rem;
      margin-left: 1rem;
    }

  }

  
  @media screen and (min-width: 482px) and (max-width: 670px) {
    h3 {
      font-size: 22px;
    }

    h4 {
      font-size: 18px;
    }

    .container-info {
      margin-right: 1rem;
      margin-left: 1rem;
    }

    .containerInfo {
      display: flex;
      flex-wrap: wrap; /* Permitir que los elementos se envuelvan en filas adicionales */
    }

    .detail {
      width: 48%;
      box-sizing: border-box;
    }

    .carousel-container {
      margin-right: 1rem;
      margin-left: 1rem;
    }

  }

  @media screen and (min-width: 320px) and (max-width: 481px) {
    h3 {
      font-size: 20px;
    }

    h4 {
      font-size: 18px;
    }

    .image-header {
      height: 45vh;
    }

    .container-info {
      margin-right: 1rem;
      margin-left: 1rem;
    }

    .rentabilidad-number {
      text-align: left; 
      font-size: 18px;
    }
    .rentabilidad-text {
      text-align: left; /* Alinear el texto a la izquierda en pantallas más pequeñas */
      font-size: 12px; 
    }

    .price {
      font-size: 18px;
      text-align: center;
    }

    .price_header {
      font-size: 16px;
    }

    .type {
      text-align: center;
      margin-bottom: 1rem;
    }

    .type_header {
      font-size: 13px;
    }

    .containerInfo {
      display: flex;
      flex-wrap: wrap; /* Permitir que los elementos se envuelvan en filas adicionales */
    }

    .detail {
      width: 100%;
    }

    .carousel-container {
      margin-right: 1rem;
      margin-left: 1rem;
    }

  }


</style>
