<template>
    <section class="privacy-policy">
        <div class="container-info">
            <h1 class="title">Política de Privacidad</h1>

            <h5 class="subtitle">Protección de datos de carácter personal</h5>
            <p>Investments Real State JJP SL informa a los usuarios de su página web www.jjpinvestments.com a través de esta Política de Privacidad sobre el tratamiento y protección de los datos de carácter personal de los usuarios que puedan ser recabados mediante su navegación o contratación de servicios que realicen en este portal. El uso de esta web por parte del usuario conlleva la aceptación por su parte de esta Política de Privacidad.
            <br><br>Investments Real State JJP SL adopta las medidas necesarias para garantizar la seguridad, integridad y confidencialidad de los datos conforme a lo establecido en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de los mismos, y en aquello no previsto por éste por la Ley Orgánica 3/2018 de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales, el Real Decreto 1720/2007, de 21 de diciembre, por el que se aprueba el Reglamento de desarrollo de la Ley Orgánica de Protección de Datos, y la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico.
            <br><br>Mediante los formularios de registro del presente sitio web o el envío de correo electrónico se recogen y tratan datos personales de cada usuario que son necesarios para la gestión y mantenimiento de algunos de los servicios prestados y cuyo tratamiento se rige por la presente Política de Privacidad.</p>

            <br><h5 class="subtitle">Responsable del tratamiento de los datos personales</h5>
            <p>El responsable del tratamiento de los datos personales que el usuario facilite al acceder y utilizar el sitio web, es, en calidad de propietario, Investments Real State JJP SL, con C.I.F. núm. B44820033, con domicilio social a estos efectos en Gran vía carlos III, 51 5º1ª, 08028 de Barcelona. Teléfono +34 666 807 004, dirección de correo electrónico de contacto ester.guerra@jjpinvestments.com, inscrita en el Registro Mercantil de Barcelona en el tomo 48742 general, Folio 64, Hoja 594527, Inscripción 1.
            <br><br>Investments Real State JJP SL se compromete a aplicar las medidas de seguridad informática previstas en la normativa citada previamente, con el objetivo de impedir el acceso o uso indebido de los datos, su manipulación, deterioro o pérdida.</p>

            <br><h5 class="subtitle">Finalidades del tratamiento de datos</h5>
            <p>Las operaciones, gestiones y procedimientos técnicos, ya sean realizados de forma automatizada como no automatizada, que posibiliten la recogida, almacenamiento, modificación, transferencia y otras acciones sobre datos de carácter personal, tienen la consideración de tratamiento de datos personales.
            <br><br>En Investments Real State JPP SL tratamos los datos personales que el usuario nos facilita a través de www.jjpinvestments.com con el fin de incluirlos en la agenda de contactos, gestionar la relación comercial solicitada y utilizar así esta información como medio de contacto de dicha empresa. Además, el tratamiento de estos datos recabados también implica gestionar el envío de la información que nos sea solicitada y poder responder a las consultas o cuestiones que el usuario remita a través del sitio web, facilitando a su vez a los interesados las ofertas de servicios que sean de su interés.
            <br><br>Los campos de los registros se deben cumplimentar obligatoriamente para que Investments Real State JPP SL pueda cumplir con las finalidades mencionadas con anterioridad.<br><br>El usuario puede oponerse al envío de comunicaciones comerciales en cualquier momento mediante el envío de un mensaje de correo electrónico a la dirección ester.guerra@jjpinvestments.com.</p>

            <br><h5 class="subtitle">Legitimación para el tratamiento de los datos</h5>
            <p>El tratamiento de los datos personales que proporciona el usuario se realiza en base a las siguientes bases jurídicas que legitiman el mismo:
            <br><br>La contratación de servicios de Investments Real State JPP SL y la ejecución del encargo profesional solicitado, cuyos términos y condiciones se pondrán a disposición del usuario de forma previa a una eventual contratación. Para poder llevar a cabo esta relación profesional solicitada, el interesado está obligado a facilitar sus datos.
            <br><br>El consentimiento libre, específico, informado e inequívoco, ya que tras la lectura de la presente Política de Privacidad el usuario queda informado y, en caso de estar conforme, puede aceptarla mediante una declaración o una clara acción afirmativa, como el marcado de una casilla que se dispone al efecto.
            <br><br>En el caso de que el interesado no facilite los citados datos o éstos sean erróneos o inexactos, no podremos atender su solicitud, resultando imposible proporcionarle la información solicitada o llevar a cabo la contratación de los servicios. Por tanto, el responsable del tratamiento, Investments Real State JPP SL, quedará exonerado de toda responsabilidad que pudiera derivarse de la no ejecución del encargo profesional o de las consecuencias que se deriven de estas informaciones inexactas o erróneas.</p>

            <br><h5 class="subtitle">Comunicación de datos</h5>
            <p>Con carácter general Investments Real State JPP SL no comunicará estos datos personales a terceros, con la salvedad de que la prestación de un servicio implique la necesidad de una relación contractual con un encargado de tratamiento y ello sea estrictamente necesario para gestionar y mantener la relación entre el usuario y la mencionada empresa, previa autorización expresa por parte del usuario. Esto se hará sólo durante el tiempo imprescindible para posibilitar la ejecución del contrato de encargo, y en las mismas condiciones y con las mismas responsabilidad que se le exigen al responsable. Una vez finalizado el encargo, el encargado del tratamiento devolverá al Responsable los datos personales y suprimirá cualquier copia de la que disponga.
            <br><br>Por otra parte, sólo tendrán derecho a acceder a estos datos personales los terceros con los que Investments Real State JPP SL tenga una obligación legal o contractual de facilitarlos, entre los que se incluyen, por ejemplo, el Defensor del Pueblo y Jueces y Tribunales interesados en los procedimientos relacionados con las reclamaciones presentadas.</p>

            <br><h5 class="subtitle">Derechos de los interesados</h5>
            <p>El usuario podrá ejercitar en todo momento, en los términos establecidos en la legislación vigente, los derechos de acceso, rectificación o supresión de datos, solicitar que se limite el tratamiento, oponerse al mismo, solicitar la portabilidad de sus datos, así como revocar el consentimiento prestado, derechos reconocidos en el citado Reglamento (UE). El ejercicio de estos derechos puede realizarlo el propio usuario dirigiéndose a Investments Real State JPP SL en la dirección de su sede social indicada anteriormente o a través del envío de un correo electrónico a ester.guerra@jjpinvestments.com.
            <br><br>Para el efectivo ejercicio de estos derechos, el usuario deberá acreditar su identidad facilitando su nombre y apellidos, fotocopia del DNI o documento identificativo equivalente que acredite su identidad, petición en la que se concrete la solicitud, dirección a efectos de notificaciones, y fecha y firma del solicitante.</p>
        </div>
    </section>
</template>

<script>

export default {
  name: 'PrivacyPolicy'
}

</script>


<style scoped>
.container-info {
  margin-right: 13rem;
  margin-left: 13rem;
  margin-top: 8rem;
  margin-bottom: 4rem;
}

h1 {
  color: #3B3A5D;
  font-size: 30px;
  text-align: center;
  padding: 0;
  margin: 0;
  margin-bottom: 3rem;
  font-family: 'Kufam';
}

h5 {
  color: #3B3A5D;
  font-size: 18px;
  font-weight: 600;
  text-align: start;
  padding: 0;
  margin: 0;
  margin-bottom: 1.5rem;
  font-family: 'Kufam';
}

p {
  font-size: 15px;
  color: #3B3A5D;
  text-align: start;
  font-family: 'Kufam';
  padding: 0;
  margin: 0;
  margin-bottom: .5rem;
}

a {
  color: #B16642;
}

@media screen and (min-width: 1300px) and (max-width: 1400px) {
  .container-info {
    margin-right: 10rem;
    margin-left: 10rem;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .container-info {
    margin-right: 8rem;
    margin-left: 8rem;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1200px) {
  .container-info {
    margin-right: 6rem;
    margin-left: 6rem;
  }
}

@media screen and (min-width: 1029px) and (max-width: 1100px) {

  .container-info {
    margin-right: 4rem;
    margin-left: 4rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1028px)  {

  h1 {
    font-size: 25px;
  }

  .container-info {
    margin-right: 2rem;
    margin-left: 2rem;
  }  
}

@media screen and (min-width: 481px) and (max-width: 768px) {

  h1 {
    font-size: 22px;
  }

  h5 {
    font-size: 17px;
  }

  p {
    font-size: 14px;
  }

  .container-info {
    margin-right: 2rem;
    margin-left: 2rem;
  } 
}

@media screen and (min-width: 320px) and (max-width: 481px) {

  h1 {
    font-size: 20px;
  }

  h5 {
    font-size: 16px;
  }

  p {
    font-size: 14px;
  }

  .container-info {
    margin-right: 2rem;
    margin-left: 2rem;
  } 
}

</style>