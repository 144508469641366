<template>
    <section class="construction">
        <div class="container-info">
            <img src="/assets/building.png" alt="Construction Web" class="image">
            <h1 class="title">Nuestro sitio web está en construcción</h1>
            <p>¡En breves estaremos listos para ti!</p>
        </div>
    </section>
</template>

<script>

export default {
    name: 'ConstructionWeb'
}

</script>


<style scoped>
.container-info {
    margin-right: 13rem;
    margin-left: 13rem;
    margin-top: 8rem;
    margin-bottom: 4rem;
}

h1 {
  color: #3B3A5D;
  font-size: 20px;
  text-align: center;
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;
  font-family: 'Kufam';
}

p {
  font-size: 15px;
  color: #3B3A5D;
  text-align: center;
  font-family: 'Kufam';
  padding: 0;
  margin: 0;
  margin-bottom: .5rem;
}

.image {
    max-width: 50%;
    height: auto;
    margin-bottom: 20px;
    border-radius: 5px;
}

@media screen and (min-width: 1300px) and (max-width: 1400px) {
    .container-info {
        margin-right: 10rem;
        margin-left: 10rem;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .container-info {
    margin-right: 8rem;
    margin-left: 8rem;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1200px) {
  .container-info {
    margin-right: 6rem;
    margin-left: 6rem;
  }
}

@media screen and (min-width: 1029px) and (max-width: 1100px) {

  .container-info {
    margin-right: 4rem;
    margin-left: 4rem;
  }

}

@media screen and (min-width: 768px) and (max-width: 1028px)  {

    h1 {
        font-size: 20px;
    }

    .container-info {
        margin-right: 2rem;
        margin-left: 2rem;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {

    h1 {
        font-size: 18px;
    }

    .container-info {
        margin-right: 2rem;
        margin-left: 2rem;
    }

}

@media screen and (min-width: 320px) and (max-width: 481px) {

    h1 {
        font-size: 18px;

    }

    .container-info {
        margin-right: 2rem;
        margin-left: 2rem;
    } 
}

</style>