<template>
  <section class="privacy-policy">
      <div class="container-info">
          <h1 class="title">Aviso Legal</h1>

          <p>Investments Real State JJP SL,responsable de este sitio web, pone a disposición de los usuarios de Internet el presente documento, cuya finalidad es cumplir las obligaciones establecidas en la Ley 34/2002, de 11 de junio, de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSICE), como en la LO 3/2018, de 5 de diciembre, de Protección de Datos de Carácter Personal y garantía de los derechos digitales, y en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 y demás normativa aplicable, así como proporcionar información sobre las condiciones de uso.</p>

          <br><h5 class="subtitle">Datos identificativos</h5>
          <p>En cumplimiento del deber de información regulado en el art. 10 LSSICE, a continuación se facilitan los datos necesarios del titular de la página web:
          <br><br>La empresa que gestiona los servicios y el dominio web es Investments Real State JJP SL con N.I.F. B44820033, con domicilio social a estos efectos en Gran vía carlos III, 51 5º1ª, 08028 de Barcelona.
          <br><br>Teléfono +34 666 807 004, dirección de correo electrónico de contacto ester.guerra@jjpinvestments.com.</p>

          <br><h5 class="subtitle">Usuarios</h5>
          <p>Toda persona, ya sea física o jurídica, que acceda, navegue, utilice o participe en los servicios y actividades, gratuitas u onerosas, desarrolladas mediante esta página web asume la condición de USUARIO, y como tal, a través de dicho acceso, se compromete a la observancia y riguroso cumplimiento de las disposiciones aquí presentes, así como a cualquier otra disposición legal que resulte de aplicación, obligándose así a hacer un uso correcto del sitio web. El usuario responderá frente a Investments Real State JJP SL. o frente a terceros, de cualesquiera daños y perjuicios que pudieran causarse como consecuencia del incumplimiento de dicha obligación.</p>

          <br><h5 class="subtitle">Modificaciones</h5>
          <p>Investments Real State JJP SL se reserva el derecho de realizar unilateralmente las modificaciones que estime pertinentes en su portal, pudiendo cambiar, suprimir o añadir tanto los contenidos como los servicios que se presten a través del mismo, sin que exista obligación de preavisar o poner en conocimiento de los usuarios dichas modificaciones, entendiéndose como suficiente con la publicación en el sitio web del prestador.
          <br><br>La duración de la prestación del servicio del portal se limita al momento en que el usuario esté conectado al sitio web o a alguno de los servicios que éste proporciona. Por ello, el usuario, en cada ocasión que pretenda utilizar el portal, debe leer detenidamente este Aviso Legal, ya que el mismo y sus respectivas condiciones de uso pueden verse alteradas en cualquier momento. Por tanto, la vigencia de las citadas condiciones variará en función de su exposición y se mantendrá mientras estén debidamente publicadas, hasta que se vean sustituidas por otras.</p>

          <br><h5 class="subtitle">Condiciones de uso del sitio web</h5>
          <p>Las presentes condiciones de uso y navegación tienen como finalidad regular la relación entre el titular de la página web, Investments Real State JJP SL, como prestador del servicio, y los usuarios que acceden, navegan y disfrutan del servicio ofrecido.
          <br><br>www.jjpinvestments.com proporciona el acceso libre y gratuito a gran cantidad de información, servicios y datos (en adelante, “los contenidos”), cuya propiedad pertenece a Investments Real State JJP SL o a sus licenciantes a los que el usuario puede tener acceso.
          <br><br>El usuario asume la responsabilidad del uso adecuado del portal de conformidad con la Ley y las presentes condiciones, responsabilidad que se extiende al registro necesario para acceder a determinados servicios y contenidos que se prestan. Dicho registro conlleva la cumplimentación del correspondiente formulario, en el cual el usuario garantiza la autenticidad y actualidad de todos aquellos datos que comunique y se compromete a no proporcionar información o documentación falsa, fraudulenta o ilícita. A raíz del mismo puede surgir la creación de una contraseña que el usuario deberá conservar diligentemente y de manera confidencial. El usuario será el único responsable de las manifestaciones falsas o inexactas que realice y de los perjuicios que cause a Investments Real State JJP SL o a terceros por la información que facilite.
          <br><br>El usuario se compromete expresamente a hacer un adecuado uso de los contenidos y servicios [por ejemplo, servicios de chat, foros de discusión, grupos de noticias…] que www.jjpinvestments.com ofrece, conforme a lo establecido en la ley, la moral, el orden público y las presentes condiciones y, con carácter enunciativo pero no limitativo, a no emplearlos para:
          <br><br>
            <ul>
              <li>Difundir contenidos delictivos, violentos, pornográficos, racistas, xenófobos, ofensivos, de apología al terrorismo, que atenten contra los derechos humanos o, en general, contrarios a la ley o al orden público.</li>
              <br><li>Introducir intencionadamente en la red virus informáticos o realizar actuaciones susceptibles de alterar, estropear, interrumpir o generar errores o daños en los sistemas físicos y lógicos de Investments Real State JJP SL], creadora del sitio web, o de terceras personas; así como obstaculizar el acceso de otros usuarios al sitio web y a sus servicios mediante el consumo masivo de los recursos informáticos a través de los cuales Investments Real State JJP SL presta sus servicios.</li>
              <br><li>Intentar acceder a las cuentas de correo electrónico de otros usuarios o a áreas restringidas de los sistemas informáticos de Investments Real State JJP SL o de terceros y, en su caso, extraer información.</li>
              <br><li>Vulnerar los derechos de propiedad intelectual o industrial, así como difundir, transmitir o poner a disposición de terceros cualquier tipo de información, elemento o contenido que suponga una violación del secreto de las comunicaciones y la legislación sobre protección de datos de carácter personal.</li>
              <br><li>Suplantar la identidad de otro usuario, de las administraciones públicas o de un tercero, utilizando sus claves de registro a los distintos servicios y/o contenidos del sitio web.</li>
              <br><li>Reproducir, copiar, distribuir, poner a disposición o de cualquier otra forma comunicar públicamente, transformar o modificar los contenidos, a menos que se cuente con la autorización del titular de los correspondientes derechos de explotación o ello resulte legalmente permitido.</li>
              <br><li>Recabar datos con finalidad publicitaria y remitir publicidad de cualquier clase y comunicaciones con fines de venta u otra de naturaleza comercial sin que medie previa solicitud o consentimiento.</li>
            </ul>
            <br>Cualquier incumplimiento de las cláusulas contenidas en el presente sitio web (Aviso Legal, Política de Privacidad, Política de Cookies, así como otros contenidos que supongan obligaciones para el usuario) y en general de la legalidad vigente en España, se comunicará inmediatamente por parte de Investments Real State JJP SL a las autoridades pertinentes, comprometiéndose ésta a cooperar con las mismas. En tal caso, el usuario responderá frente a Investments Real State JJP SL o frente a terceros, de cualesquiera daños y perjuicios que pudieran causarse como consecuencia del incumplimiento de estas obligaciones.
            <br><br>Investments Real State JJP SL no garantiza que su página web cumpla, total o parcialmente, con las legislaciones de otros países. Por tanto, si el usuario reside o se encuentra domiciliado en cualquier otro lugar que no sea España, y decide acceder y/o navegar en el presente sitio web, lo hará bajo su propia responsabilidad y riesgo, debiendo asegurarse de que tal acceso y/o navegación cumple con la legislación local aplicable en su caso.
            <br><br>Investments Real State JJP SL no se hace responsable de ningún daño o pérdida que se derive de un ataque de denegación de servicio, virus o cualquier otro programa o material tecnológicamente perjudicial que pueda afectar a su ordenador, equipo informático, datos o materiales como consecuencia del uso de esta página web o de la descarga de contenidos de la misma o a los que la misma redireccione.
          </p>

          <br><h5 class="subtitle">Notificaciones</h5>
          <p>Todas las notificaciones y comunicaciones entre los usuarios y Investments Real State JJP SL se considerarán eficaces, a todos los efectos, cuando se realicen a través de correo postal, correo electrónico o comunicación telefónica. Los usuarios deberán dirigirse a Investments Real State JJP SL mediante:
          <ul>
            <li>Envío por correo postal a la siguiente dirección: Investments Real State JJP SL, Gran vía carlos III, 51 5º1ª, 08028 de Barcelona.</li>
            <li>Comunicación por medio de llamada telefónica al número: +34 666 807 004.</li>
            <li>Envío de correo electrónico a la siguiente dirección: ester.guerra@jjpinvestments.com.</li>
          </ul>
          Mediante el uso de esta página web, el usuario acepta implícitamente que la mayor parte de las comunicaciones se realizarán por vía electrónica. A efectos contractuales, el usuario consiente usar este medio electrónico de comunicación y reconoce que toda comunicación que se le envíe de forma electrónica cumple con los requisitos legales.</p>

          <br><h5 class="subtitle">Protección de datos de carácter personal</h5>
          <p>El titular de la página web se compromete a tratar los datos personales del usuario de conformidad con lo establecido en la legislación vigente en la materia. Concretamente, se compromete a aplicar lo dispuesto en la LO 3/2018, de 5 de diciembre, de Protección de Datos de Carácter Personal y garantía de los derechos digitales, y en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016.
          <br>Para más información sobre el tratamiento de sus datos personales en el portal, diríjase a la Política de Privacidad.</p>

          <br><h5 class="subtitle">Propiedad intelectual e industrial</h5>
          <p>Todos los contenidos del sitio web, entre otros, textos, fotografías, gráficos, imágenes, iconos, tecnología, software, links y demás contenidos audiovisuales, así como su diseño gráfico y códigos fuente, constituyen una obra cuya propiedad pertenece a Investments Real State JJP SL, sin que puedan entenderse cedidos al usuario ninguno de los derechos de explotación sobre los mismos más allá de lo estrictamente necesario para el correcto uso de la web, y con independencia de que sean susceptibles o no de propiedad intelectual.
          <br><br>Asimismo, todas las marcas, nombres comerciales o signos distintivos de cualquier clase que aparecen en el sitio web son propiedad de Investments Real State JJP SL, sin que pueda entenderse que el uso o acceso al mismo atribuya al usuario derecho alguno sobre los mismos.
          <br><br>Queda prohibida la reproducción total o parcial, explotación, distribución, modificación, cesión o comunicación pública de los contenidos y cualquier otro acto que no haya sido expresamente autorizado por el titular de los derechos de explotación. Cualquier uso no autorizado previamente se considera un incumplimiento grave de los derechos de propiedad intelectual o industrial del autor.
          <br><br>Para realizar cualquier tipo de observación respecto a posibles incumplimientos de los derechos de propiedad intelectual o industrial, así como sobre cualquiera de los contenidos del sitio web, el usuario deberá notificar dicha circunstancia a Investments Real State JJP SL, adjuntando la información pertinente.
          <br><br>En cualquier caso, Investments Real State JJP SL no asume responsabilidad alguna respecto de los derechos de propiedad intelectual o industrial titularidad de terceros que se vean infringidos por un tercero o por el usuario.
        </p>

        <br><h5 class="subtitle">Enlaces</h5>
          <p>El sitio web de www.jjpinvestments.com propiedad de Investments Real State JJP SL puede contener enlaces o hipervínculos a otros sitios de Internet, respecto a los cuales no ejerce ningún tipo de control. Por tanto, una vez que el usuario acceda a los enlaces de terceros y abandone el sitio web, tanto el presente Aviso Legal, como la Política de Privacidad dejarán de surtir efecto, ya que los sitios web a los que el usuario acceda están sujetos a sus propias políticas.
          <br><br>Investments Real State JJP SL no puede asumir responsabilidad alguna por el contenido que pueda aparecer en páginas de terceros, ni garantiza la disponibilidad técnica, calidad, fiabilidad, exactitud, amplitud, veracidad, validez y constitucionalidad de cualquier material o información contenida en ninguno de dichos enlaces o hipervínculos. Asimismo, la inclusión de estas conexiones externas a sitios web ajenos no implica ningún tipo de asociación, fusión o participación con las entidades conectadas.
        </p>

        <br><h5 class="subtitle">Exclusión de garantías y responsabilidad</h5>
          <p>La información suministrada por del presente sitio web es de carácter general y tiene una finalidad meramente informativa, sin que se garantice plenamente el acceso a todos los contenidos, ni su exactitud, exhaustividad, corrección, vigencia o actualidad, ni su idoneidad o utilidad para un objetivo específico.
          <br><br>Investments Real State JJP SL no se hace responsable, en ningún caso, de los daños y perjuicios de cualquier naturaleza derivados de, a título enunciativo:
          <ul>
            <li>Errores u omisiones en los contenidos. Investments Real State JJP SL no garantiza que los contenidos vayan a estar actualizados permanentemente, ni que carezcan de cualquier tipo de error.</li>
            <li>Ausencia de disponibilidad del portal. Investments Real State JJP SL no se hace responsable de los posibles daños o perjuicios generados en el usuario como consecuencia de fallos o desconexiones en las redes de telecomunicaciones que supongan la suspensión, cancelación o interrupción del servicio del sitio web, ya que el funcionamiento de estas redes depende de terceros.</li>
            <li>Presencia de virus o programas maliciosos o lesivos en los contenidos que puedan alterar los sistemas informáticos, documentos electrónicos o datos de los usuarios, a pesar de haber adoptado todas las medidas tecnológicas necesarias para prevenirlo. Corresponde al usuario, en todo caso, estar provisto de herramientas adecuadas que le protejan ante programas informáticos dañinos.</li>
          </ul>
        </p>

        <br><h5 class="subtitle">Ley aplicable y jurisdicción</h5>
          <p>Las relaciones establecidas entre Investments Real State JJP SL, titular de la página web, y el usuario se regirá por la normativa española vigente.</p>

      </div>
  </section>
</template>

<script>

export default {
  name: 'LegalPage'
}

</script>


<style scoped>
.container-info {
margin-right: 13rem;
margin-left: 13rem;
margin-top: 8rem;
margin-bottom: 4rem;
}

h1 {
color: #3B3A5D;
font-size: 30px;
text-align: center;
padding: 0;
margin: 0;
margin-bottom: 3rem;
font-family: 'Kufam';
}

h5 {
color: #3B3A5D;
font-size: 18px;
font-weight: 600;
text-align: start;
padding: 0;
margin: 0;
margin-bottom: 1.5rem;
font-family: 'Kufam';
}

p {
font-size: 15px;
color: #3B3A5D;
text-align: start;
font-family: 'Kufam';
padding: 0;
margin: 0;
margin-bottom: .5rem;
}

a {
color: #B16642;
}

@media screen and (min-width: 1300px) and (max-width: 1400px) {
  .container-info {
    margin-right: 10rem;
    margin-left: 10rem;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .container-info {
    margin-right: 8rem;
    margin-left: 8rem;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1200px) {
  .container-info {
    margin-right: 6rem;
    margin-left: 6rem;
  }
}

@media screen and (min-width: 1029px) and (max-width: 1100px) {

  .container-info {
    margin-right: 4rem;
    margin-left: 4rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1028px)  {

  h1 {
    font-size: 25px;
  }

  .container-info {
    margin-right: 2rem;
    margin-left: 2rem;
  }  
}

@media screen and (min-width: 481px) and (max-width: 768px) {

  h1 {
    font-size: 22px;
  }

  h5 {
    font-size: 17px;
  }

  p {
    font-size: 14px;
  }

  .container-info {
    margin-right: 2rem;
    margin-left: 2rem;
  } 
}

@media screen and (min-width: 320px) and (max-width: 481px) {

  h1 {
    font-size: 20px;
  }

  h5 {
    font-size: 16px;
  }

  p {
    font-size: 14px;
  }

  .container-info {
    margin-right: 2rem;
    margin-left: 2rem;
  } 
}

</style>