<template>
  <section class="home-page" id="Inicio">
    <header>
      <div class="container-header animation">
        <p class="subtitle">Descubre lo fácil que es invertir...</p>
        <h1>y da el primer paso hacia tu libertad financiera.</h1>
        <p class="re-subtitle">Personal Shopper Inmobiliario</p>
      </div>

      <div class="carousel">
        <transition-group name="carousel-transition" tag="div" class="carousel-items">
          <div class="carousel-item" v-for="(bq, index) in images" :key="index" :class="{ 'active': index === currentIndex }">
            <img :src="bq" alt="Carousel Image">
          </div>
        </transition-group>
      </div>

    </header>
    <section class="about-us">
      <div class="about-us-container">
        <div class="container-text-aboutus animation">
          <div class="titles-container">
            <p class="number">01</p>
            <p class="title-container">NUESTRO OBJETIVO</p>
          </div>
          <h3>¿Que te ofrecemos en JJP?</h3>
          <p class="number-about-us">1.</p>
          <p class="about-us-text">Ponemos a tu servicio nuestra amplia experiencia como Personal Shopper Inmobiliario orientado a la Rentabilidad de nuestros inversores. Ayudamos a inversores y a empresas a conseguir una inversión Rentable y Pasiva, siguiendo una metodología propia de trabajo.</p>
          <p class="number-about-us">2.</p>
          <p class="title-about-us">Servicio llaves en mano</p>
          <p class="about-us-text">Diseñamos la propuesta de inversión inmobiliaria que mejor se adapta a tus objetivos e intereses. Esta propuesta incluye la búsqueda del inmueble, análisis de rentabilidad, búsqueda de financiación, reforma, y puesta en marcha con el mejor perfil de inquilino.</p>
          <p class="number-about-us">3.</p>
          <p class="title-about-us">Descubre nuestro plan relax</p>
          <p class="about-us-text">Con nuestro servicio “Plan Relax”, nuestros clientes nos confían la gestión integral de la gestión del alquiler, asegurando el cobro mensual de los alquileres de sus inmuebles. ¡Nuestro plan relax te lo pone así de fácil! <br><br> Ayudamos a inversores y a empresas a conseguir una inversión Rentable y Pasiva, siguiendo una metodología propia de trabajo que comienza con un riguroso análisis de mercado.</p>
          <button class="btn-contact animation"><router-link to="/ContactForm" class="router-link-exact-active">¡Contáctanos!</router-link></button>
        </div>
      </div>
    </section>
    <section class="services" id="Servicio">
      <div class="services-container">
        <div class="titles-container-center animation">
          <p class="number">02</p>
          <p class="title-container">SERVICIO INTEGRAL</p>
        </div>
        <img class="pattern-right" src="../assets/pattern.png" alt="Design">
        <h3 class="titles-center animation">Descubre nuestros 8 servicios de alto valor</h3>
        <div class="services-text animation">
          <div class="container-icon">
            <img class="icon" src="../assets/business.png" alt="Business Plan">
            <div></div>
            <h5 class="title-icon">Business plan</h5>
            <p class="text-icon">Llevamos a cabo una detallada labor de análisis donde se diseña la estrategia ideal para conseguir los objetivos deseados a 1, 5 y 10 años.</p>
          </div>
          <div class="container-icon">
            <img class="icon" src="../assets/search.png" alt="Búsqueda">
            <h5 class="title-icon">Búsqueda de la propiedad</h5>
            <p class="text-icon">Después de analizar el perfil de inversor, focalizamos en ofrecerle la propiedad que mejor se ajusta a su necesidad.</p>
          </div>
          <div class="container-icon">
            <img class="icon" src="../assets/negotation.png" alt="Negociación">
            <h5 class="title-icon">Negociación</h5>
            <p class="text-icon">Llevamos a cabo una labor de negociación muy pormenorizada de cada inmueble, con la finalidad de obtener el umbral de precio ideal que proporcione la rentabilidad pactada.</p>
          </div>
          <div class="container-icon">
            <img class="icon" src="../assets/finances.png" alt="Financiación">
            <h5 class="title-icon">Financiación</h5>
            <p class="text-icon">Asesoramos al inversor respecto a las diferentes opciones planteadas por parte de las entidades financieras y le facilitamos sus contactos con el fin de conseguirle el mejor tipo de interés.</p>
          </div>
        </div>
        <div class="services-text animation">
          <div class="container-icon">
            <img class="icon" src="../assets/person.png" alt="Acompañamiento">
            <h5 class="title-icon">Acompañamiento en la compra</h5>
            <p class="text-icon">Damos cobertura al inversor el día de la compraventa para asegurarnos que toda la documentación está en regla.</p>
          </div>
          <div class="container-icon">
            <img class="icon" src="../assets/reform.png" alt="Reforma">
            <h5 class="title-icon">Reforma</h5>
            <p class="text-icon">Diseñamos, valoramos y ejecutamos una reforma orientada a maximizar la rentabilidad, evitando desviaciones económicas y de tiempos.</p>
          </div>
          <div class="container-icon">
            <img class="icon" src="../assets/rental.png" alt="Alquiler">
            <h5 class="title-icon">Puesta en marcha del alquiler</h5>
            <p class="text-icon">Realizamos la búsqueda del inquilino ideal, getionamos los contratos y depositamos la fianza en el organismo oficial de cada comunidad.</p>
          </div>
          <div class="container-icon">
            <img class="icon" src="../assets/relax.png" alt="Relax">
            <h5 class="title-icon">Plan relax</h5>
            <p class="text-icon">Servicio de alquiler completamente pasivo, donde te aseguramos el cobro cada día 6 del mes y nos ocupamos de todas las gestiones que surjan sobre el inmueble con tu inquilino.</p>
          </div>
        </div>
      </div>
    </section>
    <section class="oportunities" id="Exitos">
      <div class="oportunities-container">
        <div class="titles-container-center animation">
          <p class="number">03</p>
          <p class="title-container">ÉXITOS</p>
        </div>
        <h3 class="titles-center animation">¿Quieres ver algunos de nuestros éxitos?</h3>
        <div class="containerVivienda animation">
          <div class="vivienda-container animation" v-for="(property, index) in properties" :key="index">
            <img class="image" :src="property.image" alt="Piso de Investments Real State">
            <div class="rentabilidad">
              <h5 class="title-vivienda">{{ property.title }}</h5>
              <!-- <button class="btn-vivienda" @click="navigateToPropertyDetail(property)">Saber más</button> -->
              <router-link class="btn-vivienda" :to="{ name: 'PropertyDetail', params: { property: property } }">Saber más</router-link>
            </div>
            <div class="line"></div>
            <div class="containerInfo">
              <div class="infoExits">
                <p class="price">{{ property.zona }}</p>
                <p class="type">Zona</p>
              </div>
              <div>
                <p class="price">{{ property.alquiler }}</p>
                <p class="type">Alquiler</p>
              </div>
            </div>
          </div>
        </div>
        <button class="btn-oportunities animation"><router-link to="/ContactForm" class="router-link-exact-active">¡Me interesa saber más! <i class="fas fa-arrow-circle-right fa" style="color: #FFBB50"></i></router-link></button>
      </div>
    </section>
    <section class="services" id="Nosotros">
      <div class="services-container">
        <div class="titles-container-center animation">
          <p class="number">04</p>
          <p class="title-container">QUIEN SOMOS</p>
        </div>
        <!-- <img class="pattern-right" src="../assets/pattern.png" alt="Design"> -->
        <h3 class="titles-center animation">Conócenos mejor</h3>
        <p class="about-us-text animation">Expertos en el sector inmobiliario con más de 20 años de experiencia, te guiaremos en el emocionante viaje de encontrar la inversión perfecta, <b>completamente pasiva</b> donde seremos los responsables de todo el proceso.<br><br>Nuestra finalidad es <b>escuchar al inversor</b>, entenderlo y ayudarlo a conseguir sus sueños, batallando por sus intereses para encontrar la propiedad ideal.<br><br>Nuestra <b>pasión</b> con el mundo inmobiliario nos motiva a conseguir un compromiso con la excelencia y la transparencia, que garantiza la seguridad, confianza y satisfacción del cliente.
        <br><br><b>Jorge Claramunt Manzanal</b><br><em>CEO Fundador e Inversor Inmobiliario</em><br><br>Entusiasta de los bienes inmuebles por tradición familiar, y con experiencia en el sector desde 2008, te ayudará a encontrar tu inmueble ideal.<br><br>Ingeniero de Organización como formación y con experiencia internacional en Europa y Asia en multinacionales del sector de la automoción, ha fortalecido sus capacidades de negociación y entendimiento de las necesidades de cliente.<br><br>Inversor Inmobliario con propiedades en Barcelona, Valencia, Castellón y Lerida.<br><br>Miembro de la plataforma de inversores inmobiliarios de Zona 3, se encargará de solucionar todas tus dudas y sabrá encontrar la propiedad que más se ajuste a tus necesidades.
        <br><br><b>Juan Pablo Molina Casas</b><br><em>CEO Fundador e Inversor Inmobiliario</em><br><br>Apasionado de la inversión inmobiliaria e inversor desde el 2003 en Barcelona, Castellon, Lérida y Tarragona, posee una gran experiencia en el sector inmobiliario para inversores.<br><br>Ingeniero Técnico Mecánico, ha trabajo en constructoras a nivel nacional desde 1999, con una dilatada experiencia en proyectos de construcción en toda la península ibérica. Experto en ver soluciones constructivas donde otros solo son capaces de ver un problema.
        <br><br>Se ha formado con grandes expertos del sector y referentes en el mundo de la inversión para especializarse tanto en alquiler tradicional como en alquiler de habitaciones.</p>
        <div class="images-team animation">
          <img class="image-team" src="../assets/juan_pablo.png" alt="Juan Pablo Molina">
          <img class="image-team" src="../assets/team_ceo.png" alt="Equipo">
          <img class="image-team" src="../assets/jorge_claramunt.png" alt="Jorge Claramunt">
        </div>
      </div>
    </section>
    <section class="reviews">
      <div class="titles-container-center animation">
        <p class="number">05</p>
        <p class="title-container">TESTIMONIOS</p>
      </div>
      <h3 class="titles-center animation">Hablan de nosotros</h3>

      <div class="carousel-container animation">
        <carousel :perPage="perPage">
          <slide v-for="(review, index) in reviews" :key="index">
            <div class="review-card" :src="image" :alt="'Slide ' + (index + 1)">
              <img class="quote" src="../assets/quote.svg" alt="Cita">
              <p class="text-review">{{ review.text }}</p>
              <p class="author-review">{{ review.author }}</p>
            </div>
          </slide>
        </carousel>
      </div>
    </section>
  </section>
</template>

<script>

import { Carousel, Slide } from 'vue-carousel';

export default {
  name: 'HomeApp',
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      currentImageIndex: 0,
      lastScrollTop: 0,
      offset: 0,
      slideWidth: 600,
      currentIndex: 0,
      intervalId: null,
      perPage: 3,
      image: "/assets/scroll_1.png",
      images: [
        '/assets/scroll_1.png',
        '/assets/scroll_2.png',
        '/assets/scroll_3.png',
        '/assets/scroll_4.png',
        '/assets/scroll_5.png',
        '/assets/scroll_6.png',
      ],
      reviews: [
        { text: '¡Excelentes profesionales! Además de su conocimiento del sector, destacaría su claridad, honestidad y flexibilidad. Un punto diferencial con otros personal shoppers es la posibilidad de contratar el servicio de reforma para el que cuentan con buenos equipos para llevarlo a cabo, lo cual, sin duda, añade muchísimo valor.', author: 'Claudia Moreno' },
        { text: 'A fecha de hoy ya he adquirido tres inmuebles contratando su servicio "llave en mano". De ellos destacaría su profesionalidad, honestidad y claridad desde el primer momento y, sobretodo, su trato cercano y continuo.', author: 'Adrián' },
        { text: '¡Impecables! La experiencia ha sido excelente y muy fluida, tienen una amplia una red de contatos consolidada que les permite encontrar oportunidades y gestionar el proyecto de principio a fin (compra-reforma-alquiler), comunicando constantemente en que punto están. Como comprador, te sientes plenamente respaldado por su gran control de procesos. ¡Sin duda repetiré!', author: 'Sonia M.' },
        { text: 'En 9 meses hemos cerrado ya tres operaciones y las que ya están alquiladas con unos márgenes mas que aceptables, dos en tradicional y una por habitaciones. Se encargan de todo antes de la compra, búsqueda y selección según los parámetros indicados, acompañamiento en la negociación y en la firma de arras y ante notario. Grandes profesionales, pero también buenas personas en las que confiar la producción de tus inmuebles de inversión.', author: 'Gabriel Aponte' },
        { text: 'Experiencia y expertise en el mercado inmobiliario para realizar análisis en profundidad de las opciones disponibles y poder ofrecer las propiedades que mejor se ajustan a los objetivos y necesidades de cada inversor. Otra ventaja importante que ofrecen es que proporcionan un análisis detallado de la rentabilidad potencial de cada propiedad, con el objetivo de maximizar los beneficios y minimizar los riesgos en este tipo de inversiones.', author: 'Carol Pérez' },
      ],
      properties: [ // Información de los pisos
        {
          id: "1",
          title: "Piso en Lérida",
          rentabilidad: "13.17",
          zona: "Av. de les Garrigues",
          alquiler: "Habitaciones",
          compra: "85.800€",
          reforma: "19.525€",
          mensual: "1.250€",
          total: "113.905€",
          image: "../assets/garrigues/garrigues_1.jpg",
          location: "Lérida",
          superficie: "120 m2",
          habitaciones: 5,
          baños: 2,
          images: ["../assets/garrigues/garrigues_1.jpg", "../assets/garrigues/garrigues_2.jpg","../assets/garrigues/garrigues_3.jpg", "../assets/garrigues/garrigues_4.jpg", "../assets/garrigues/garrigues_5.jpg", "../assets/garrigues/garrigues_6.jpg",
          "../assets/garrigues/garrigues_6.jpg", "../assets/garrigues/garrigues_7.jpg", "../assets/garrigues/garrigues_8.jpg", "../assets/garrigues/garrigues_9.jpg", "../assets/garrigues/garrigues_10.jpg", "../assets/garrigues/garrigues_11.jpg",
          "../assets/garrigues/garrigues_12.jpg", "../assets/garrigues/garrigues_13.jpg", "../assets/garrigues/garrigues_14.jpg"]
        },
        {
          id: "2",
          title: "Piso en Lérida",
          rentabilidad: "9.78",
          zona: "Corts Catalanes, 25",
          alquiler: "Convencional",
          compra: "55.000€",
          reforma: "16.150€",
          mensual: "625€",
          total: "76.650€",
          image: "../assets/catalanes/catalanes_1.jpg",
          location: "Lérida",
          superficie: "67 m2",
          habitaciones: 3,
          baños: 1,
          images: ["../assets/catalanes/catalanes_1.jpg", "../assets/catalanes/catalanes_2.jpg", "../assets/catalanes/catalanes_3.jpg", "../assets/catalanes/catalanes_4.jpg", "../assets/catalanes/catalanes_5.jpg", "../assets/catalanes/catalanes_6.jpg", "../assets/catalanes/catalanes_7.jpg","../assets/catalanes/catalanes_8.jpg",
          "../assets/catalanes/catalanes_9.jpg", "../assets/catalanes/catalanes_10.jpg", "../assets/catalanes/catalanes_11.jpg", "../assets/catalanes/catalanes_12.jpg", "../assets/catalanes/catalanes_13.jpg", "../assets/catalanes/catalanes_14.jpg"]
        },
        {
          id: "3",
          title: "Piso en Villarreal",
          rentabilidad: "12.13",
          zona: "Sagunt",
          alquiler: "Convencional",
          compra: "36.300€",
          reforma: "12.000€",
          mensual: "525€",
          total: "51.930€",
          image: "../assets/sagunt/sagunt_2.jpg",
          location: "Villarreal",
          superficie: "100 m2",
          habitaciones: 3,
          baños: 1,
          images: ["../assets/sagunt/sagunt_1.jpg", "../assets/sagunt/sagunt_2.jpg", "../assets/sagunt/sagunt_3.jpg", "../assets/sagunt/sagunt_4.jpg", "../assets/sagunt/sagunt_5.jpg", "../assets/sagunt/sagunt_6.jpg",
          "../assets/sagunt/sagunt_7.jpg", "../assets/sagunt/sagunt_8.jpg", "../assets/sagunt/sagunt_9.jpg"]
        },
        {
          id: "5",
          title: "Piso en Lérida",
          rentabilidad: "10.15",
          zona: "Pje.Ampurdan, 1",
          alquiler: "Convencional",
          compra: "46.200€",
          reforma: "14.230€",
          mensual: "550€",
          total: "65.050€",
          image: "../assets/ampurdan/ampurdan_12.jpg",
          location: "Lérida",
          superficie: "65 m2",
          habitaciones: 2,
          baños: 1,
          images: ["../assets/ampurdan/ampurdan_1.jpg", "../assets/ampurdan/ampurdan_2.jpg","../assets/ampurdan/ampurdan_3.jpg", "../assets/ampurdan/ampurdan_4.jpg", "../assets/ampurdan/ampurdan_5.jpg", "../assets/ampurdan/ampurdan_6.jpg", "../assets/ampurdan/ampurdan_7.jpg",
          "../assets/ampurdan/ampurdan_8.jpg", "../assets/ampurdan/ampurdan_9.jpg", "../assets/ampurdan/ampurdan_10.jpg", "../assets/ampurdan/ampurdan_11.jpg", "../assets/ampurdan/ampurdan_12.jpg", "../assets/ampurdan/ampurdan_13.jpg"]
        },
        {
          id: "8",
          title: "Piso en Burriana",
          rentabilidad: "15.94",
          zona: "Industria, 3",
          alquiler: "Habitaciones",
          compra: "46.000€",
          reforma: "36.000€",
          mensual: "1.150€",
          total: "86.600€",
          image: "../assets/industria/industria_6.jpg",
          location: "Burriana",
          superficie: "93 m2",
          habitaciones: 4,
          baños: 2,
          images: ["../assets/industria/industria_1.jpg", "../assets/industria/industria_2.jpg", "../assets/industria/industria_3.jpg", "../assets/industria/industria_4.jpg", "../assets/industria/industria_5.jpg",
          "../assets/industria/industria_6.jpg", "../assets/industria/industria_7.jpg", "../assets/industria/industria_8.jpg"]
        },
        {
          id: "9",
          title: "Piso en Villarreal",
          rentabilidad: "17.43",
          zona: "Gamboa, 33",
          alquiler: "Habitaciones",
          compra: "53.000€",
          reforma: "14.000€",
          mensual: "1.050€",
          total: "72.300€",
          image: "../assets/gamboa/gamboa_2.jpg",
          location: "Villarreal",
          superficie: "78 m2",
          habitaciones: 4,
          baños: 1,
          images: ["../assets/gamboa/gamboa_1.jpg", "../assets/gamboa/gamboa_2.jpg", "../assets/gamboa/gamboa_3.jpg","../assets/gamboa/gamboa_4.jpg","../assets/gamboa/gamboa_5.jpg","../assets/gamboa/gamboa_6.jpg","../assets/gamboa/gamboa_7.jpg",
          "../assets/gamboa/gamboa_8.jpg","../assets/gamboa/gamboa_9.jpg","../assets/gamboa/gamboa_10.jpg","../assets/gamboa/gamboa_11.jpg"]
        },
        {
          id: "10",
          title: "Piso en Villarreal",
          rentabilidad: "12.28",
          zona: "Teruel, 7",
          alquiler: "Convencional",
          compra: "46.000€",
          reforma: "700€",
          mensual: "525€",
          total: "51.300€",
          image: "../assets/teruel/teruel_1.jpg",
          location: "Villarreal",
          superficie: "69 m2",
          habitaciones: 3,
          baños: 1,
          images: ["../assets/teruel/teruel_1.jpg", "../assets/teruel/teruel_2.jpg", "../assets/teruel/teurel_3.jpg", "../assets/teruel/teruel_4.jpg", "../assets/teruel/teruel_5.jpg", "../assets/teruel/teruel_6.jpg", "../assets/teruel/teruel_7.jpg",
          "../assets/teruel/teruel_8.jpg", "../assets/teruel/teruel_9.jpg", "../assets/teruel/teruel_10.jpg", "../assets/teruel/teruel_11.jpg", "../assets/teruel/teruel_12.jpg", "../assets/teruel/teruel_13.jpg"]
        },
      ]
    };
  },
  beforeRouteUpdate(to, from, next) {
    window.scrollTo(0, 0);
    next();
  },
  beforeRouteLeave(to, from, next) {
    window.scrollTo(0, 0);
    next();
  },
  computed: {
    currentImage() {
      return this.images[this.currentImageIndex];
    },
    extendedReviews() {
      return [...this.reviews, ...this.reviews];
    },
  },
  mounted() {
    this.startCarousel(),
    window.addEventListener('scroll', this.handleScroll);
    if (window.innerWidth < 481) {
      this.perPage = 1;
    } else if (window.innerWidth > 481 && window.innerWidth < 1024) {
      this.perPage = 2;
    } else {
      this.perPage = 3;
    }
  },
  methods: {
    startCarousel() {
      this.intervalId = setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.images.length
      }, 5000)
    },
    moveSlide(direction) {
      const step = direction * this.slideWidth;
      this.offset = Math.max(Math.min(this.offset + step, 0), -((this.images.length - 1) * this.slideWidth));
    },
    handleScroll() {
      let sections = document.querySelectorAll('.animation');
      sections.forEach(section => {
        let position = section.getBoundingClientRect();
        if (position.top < window.innerHeight && position.bottom >= 0) {
          section.classList.add('active');
        } else {
          section.classList.remove('active');
        }
      });
    },
    
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    },
    
    changeImage(step) {
      if (step === -1 && this.currentImageIndex === 0) {
        return;
      }

      this.currentImageIndex += step;
      
      if (this.currentImageIndex < 0) {
        this.currentImageIndex = this.images.length - 1;
      } else if (this.currentImageIndex >= this.images.length) {
        this.currentImageIndex = 0;
      }
    },

    navigateToPropertyDetail(property) {
    this.$router.push({ name: 'PropertyDetail', props: { property } });
  }
}
};
</script>

<style scoped>

  h1 {
    color: white;
    text-shadow: 2px 2px 4px #3B3A5D;
    font-size: 45px;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
    line-height: 1.2;
    font-family: 'Kufam';
  }

  h3 {
    color: #3B3A5D;
    font-size: 25px;
    justify-content: center;
    padding: 0;
    margin: 0;
    margin-bottom: 1rem;
    font-family: 'Kufam';
  }

  .carousel {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
  }

  .carousel-items {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
  }

  .carousel-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 100%;
  }

  .carousel-item.active {
    opacity: 1;
  }

  .carousel-transition-enter-active,
  .carousel-transition-leave-active {
    transition: opacity 0.5s ease-in-out;
  }

  .carousel-transition-enter,
  .carousel-transition-leave-to {
    opacity: 0;
  }

  .container-header {
    position: absolute;
    align-self: center;
    justify-content: center;
    align-content: 'center';
    left: 0;
    right: 0;
    top: 250px;
    z-index: 99;
  }

  .subtitle {
    font-size: 25px;
    font-weight: bold;
    color: white;
    text-align: center;
    text-shadow: 2px 2px 4px #3B3A5D;
    font-family: 'Kufam';
    padding: 0;
    margin: 0;
  }

  .re-subtitle {
    font-size: 30px;
    font-weight: bold;
    color: white;
    text-align: center;
    text-shadow: 2px 2px 4px #3B3A5D;
    font-family: 'Kufam';
    padding: 0;
    margin: 0;
  }

  .titles-center {
    text-align: center;
  }

  .about-us {
    background-color: #F2F2F2;
    border-radius: 5px;
    padding: 30px;
    margin-right: 12rem;
    margin-left: 12rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .about-us-container {
    flex-direction: column;
  }
  
  .container-text-aboutus {
    width: 100%;
  }

  .titles-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .titles-container-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .about-us-text {
    text-align: center;
    font-size: 16px;
    color: #3B3A5D;
    margin: 0;
    padding: 0;
    font-family: 'Kufam';
  }

  .number-about-us {
    text-align: center;
    font-size: 30px;
    color: #FFBB50;
    font-weight: 600;
    margin: 0;
    padding: 0;
    margin-top: 20px;
    font-family: 'Kufam';
  }

  .title-about-us {
    text-align: center;
    font-size: 20px;
    color: #3B3A5D;
    font-weight: 600;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    font-family: 'Kufam';
  }

  .number {
    color: #FFBB50;
    letter-spacing: 2px;
    font-size: 12px;
    margin: 0;
    padding: 0;
    margin-right: 10px;
    font-family: 'Kufam';
  }

  .title-container {
    color: #3B3A5D;
    letter-spacing: 2px;
    font-size: 12px;
    margin: 0;
    padding: 0;
    font-family: 'Kufam';
  }

  .images-team {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    padding: 0;
  }

  .image-team {
    width: 25%;
    margin-right: 1rem;
    margin-left: 1rem;
    transition: transform 0.3s ease-in-out;
  }

  .image-team:hover  {
    transform: scale(1.1);
  }

  .carousel-container {
    overflow-x: auto;
    margin-bottom: 3rem;
    width: 100%;
  }

  .review-carousel {
    display: flex;
    animation: slide 20s infinite linear;
  }

  .review-card {
    position: relative;
    text-align: left !important;
    background-color: rgba(242, 242, 242, 0.5);
    width: 90%;
    height: 90%;
    padding: 20px;
    padding-bottom: 50px;
    margin-right: 1rem;
    margin-left: 1rem;
    box-sizing: border-box;
    text-align: center;
    border-radius: 5px;
    margin-top: 2rem;
  }

  .quote {
    width: 35px;
    height: 35px;
  }

  .text-review {
    font-size: 17px;
    color: #3B3A5D;
    text-align: left;
    padding-bottom: 1rem;
  }

  .author-review {
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 0;
    font-size: 18px;
    font-weight: 600;
    color: #3B3A5D;
    text-align: left;
  }

  @keyframes slide {
    0% { transform: translateX(0); }
    100% { transform: translateX(-100%); }
  }

  .oportunities {
    padding-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 4rem;
  }

  .oportunities-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    flex-direction: column;
  }

  .vivienda-container {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    background-color: white;
    padding: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 5px;
    width: calc(50% - 10px); /* Calcula el ancho de cada resultado */
    margin-bottom: 1.5rem;
    box-sizing: border-box;
  }

  .image {
    border-radius: 5px;
    width: 100%;
    height: 200px;
    max-height: 200px;
    margin-bottom: 20px;
  }

  .pattern {
    width: 220px;
    position: absolute;
    left: 0;
    height: auto;
    margin: 0;
    padding: 0;
  }

  .pattern-right {
    width: 220px;
    position: absolute;
    right: 0;
    height: auto;
    margin: 0;
    padding: 0;
    transform: scaleX(-1);
  }

  .rentabilidad {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .rentabilidad-container {
    display: flex;
    align-items: baseline;
  }

  .rentabilidad-number{
    color: #FFBB50;
    font-size: 25px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    margin-right: 5px;
    font-family: 'Kufam';
  }

  .rentabilidad-text {
    color: #FFBB50;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    font-family: 'Kufam';
  }

  .btn-vivienda {
    background-color: #FFBB50;
    border-radius: 5px;
    padding: 10px;
    color: #3B3A5D;
    font-size: 13px;
    border: 0;
    margin: 0;
    text-decoration: none;
    transition: background-color 0.3s ease;
    font-family: 'Kufam';
  }

  .btn-vivienda:hover {
    background-color: #FFE6BD;
  }

  .title-vivienda {
    font-size: 18px;
    color: #3B3A5D;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-top: 10px;
    font-family: 'Kufam';
  }

  .containerVivienda {
    margin-top: 2.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .containerInfo {
    display: flex;
    align-items: center;
  }

  .infoExits {
    margin-right: 2rem;
  }

  .price {
    font-size: 18px;
    color: #3B3A5D;
    font-weight: 600;
    text-align: left;
    margin: 0;
    padding: 0;
    font-family: 'Kufam';
  }

  .type {
    font-size: 14px;
    color: #8D8D8D;
    text-align: left;
    margin: 0;
    padding: 0;
    font-family: 'Kufam';
  }

  .last-type {
    font-size: 14px;
    color: #8D8D8D;
    text-align: left;
    margin: 0;
    padding: 0;
  }

  .btn-contact {
    margin-top: 3rem;
    background-color: #FFBB50;
    border: 0;
    padding: 15px;
    border-radius: 5px;
    font-size: 16px;
    color: #3B3A5D;
    font-weight: 500;
    transition: background-color 0.3s ease;
    font-family: 'Kufam';
  }

  .router-link-exact-active {
    text-decoration: none;
    color: #3B3A5D;
  }

  .btn-contact:hover {
    transition: background-color 0.3s ease;
    background-color: #FFE6BD;
  }

  .btn-oportunities {
    margin-top: 3rem;
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border: 0;
    padding: 15px;
    border-radius: 5px;
    font-size: 16px;
    color: #3B3A5D;
    transition: background-color 0.3s ease;
    font-family: 'Kufam';
  }

  .btn-oportunities:hover {
    transition: background-color 0.3s ease;
    background-color: #FFE6BD;
  }

  .image-container {
    width: 100%; /* The image will occupy the full width of the parent container */
    max-width: 500px; /* Set a maximum width as needed */
    height: 250px;
    margin: 0 auto; /* Center the container in the parent if it's wider than the content */
  }

  .image-transformation {
    width: 100%; /* Ensure the image expands to fill the container */
    height: 90%; /* Adjust the height to 100% of the container */
    border-radius: 5px; /* Add rounded corners if necessary */
    object-fit: cover; /* Adjust as needed: 'contain', 'fill', 'none', etc. */
  }

  .buttons-image-container {
    margin-top: 15px;
    margin-bottom: 3rem;
    align-self: center;
    display: flex;
    justify-content: center;
  }

  .buttons-image {
    transition: transform 0.3s ease; 
  }

  .buttons-image:hover {
    transform: scale(1.1);
  }

  .services {
    background-color: #F5F1EF;
    padding: 3rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    margin-bottom: 4rem;
  }

  .services-container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    flex-direction: column;
  }

  .container-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    width: 400px;
  }

  .icon {
    width: 80px;
    height: auto;
    margin: 0;
    padding: 0;
  }

  .title-icon {
    margin: 10px 0;
    padding: 0;
    color: #3B3A5D;
    font-size: 16px;
    font-family: 'Kufam';
  }

  .text-icon {
    display: none;
    margin: 0;
    padding: 0;
    color: #8D8D8D;
    font-size: 15px;
    max-width: 80%; /* Ajusta este valor según sea necesario */
    font-family: 'Kufam';
  }

  .container-icon:hover .text-icon {
    display: block;
  }

  .services-text {
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    margin-bottom: 2rem;
  }

  .line {
    height: 1px;
    background-color: rgba(141,141,141,0.3);
    margin: 10px 0;
    margin-bottom: 25px;
  }

  .animation {
    opacity: 0;
    transform: translateY(60px);
    transition: opacity 0.8s, transform 0.8s;
  }
  .animation.active {
    opacity: 1;
    transform: translateY(0);
  }

  @media screen and (min-width: 768px) and (max-width: 1150px) {
    h1 {
      font-size: 32px;
    }

    h3 {
      font-size: 22px;
    }

    .carousel {
      height: 80vh;
    }

    .container-header {
      top: 220px;
      margin-right: 1rem;
      margin-left: 1rem;
    }

    .number-about-us {
      font-size: 22px;
    }

    .subtitle {
      font-size: 22px;
    }

    .re-subtitle {
      font-size: 25px;
    }

    .about-us {
      margin-right: 8rem;
      margin-left: 8rem;
    }

    .oportunities {
      padding: 1rem 0;
      margin-bottom: 3rem;
    }

    .container-icon {
      margin-top: 10px;
    }

    .title-icon {
      font-size: 16px;
    }

    .text-icon {
      margin-bottom: 1rem;
      font-size: 14px;
    }

    .price {
      font-size: 16px;
    }

    .oportunities-container {
      padding-right: 1rem; /* Ajusta el relleno para dispositivos móviles */
      padding-left: 1rem;
    }

    .vivienda-container {      
      padding: 25px;
      padding-top: 25px;
      padding-bottom: 25px;
    }

    .image {
      height: auto; /* Ajusta la altura de la imagen para dispositivos móviles */
      max-width: 100%; /* Ajusta el ancho máximo de la imagen para dispositivos móviles */
      margin-bottom: 20px;
      border-radius: 5px;
    }

    .rentabilidad {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }

    .rentabilidad-container {
      display: flex;
      align-items: baseline;
    }

    .rentabilidad-number {
      font-size: 18px; /* Ajusta el tamaño de fuente para dispositivos móviles */
    }

    .rentabilidad-text {
      font-size: 12px; /* Ajusta el tamaño de fuente para dispositivos móviles */
    }

    .title-vivienda {
      font-size: 16px; /* Ajusta el tamaño de fuente para dispositivos móviles */
    }

    .btn-oportunities {
      margin-top: 2rem; /* Ajusta el margen superior para dispositivos móviles */
    }

    .about-us-text {
      font-size: 15px;
      margin-right: 1rem;
      margin-left: 1rem;
    }

    .image-container {
      width: 100%; /* The image will occupy the full width of the parent container */
      max-width: 500px; /* Set a maximum width as needed */
      height: 250px;
      margin: 0 auto; /* Center the container in the parent if it's wider than the content */
    }

    .review-card {
      flex: 0 0 50%;
    }

    .text-review {
      font-size: 17px;
    }

    .author-review {
      font-size: 16px;
    }
  }

  /* Media query para tamaños de pantalla pequeños */
  @media screen and (min-width: 481px) and (max-width: 769px) {
    h1 {
      font-size: 30px;
    }

    h3 {
      font-size: 20px;
    }

    .container-header {
      top: 170px;
      margin-right: 1rem;
      margin-left: 1rem;
    }

    .subtitle {
      font-size: 20px;
    }

    .re-subtitle {
      font-size: 22px;
    }

    .carousel {
      height: 60vh;
    }

    .number-about-us {
      font-size: 20px;
    }

    .about-us {
      margin-right: 4rem;
      margin-left: 4rem;
    }

    .price {
      font-size: 14px;
    }

    .oportunities {
      margin-bottom: 2rem;
    }

    .text-icon {
      margin-right: 1rem;
      margin-left: 1rem;
      margin-bottom: 1rem;
    }

    .services-text {
      flex-direction: column;
      align-items: center;
    }

    .container-icon {
      margin-top: 10px;
    }

    .title-icon {
      font-size: 16px;
    }

    .text-icon {
      font-size: 14px;
      max-width: 100%;
    }

    .oportunities-container {
      padding-right: 1rem;
      padding-left: 1rem;
    }

    .containerVivienda {
      flex-direction: column;
      align-items: center;
    }

    .vivienda-container {
      padding: 25px;
      padding-top: 25px;
      padding-bottom: 25px;
      width: 70%;
    }

    .image {
      height: auto; /* Ajusta la altura de la imagen para dispositivos móviles */
      max-width: 100%; /* Ajusta el ancho máximo de la imagen para dispositivos móviles */
      margin-bottom: 20px;
      border-radius: 5px;
    }

    .rentabilidad {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }

    .rentabilidad-container {
      display: flex;
      align-items: baseline;
    }

    .rentabilidad-number {
      font-size: 18px; /* Ajusta el tamaño de fuente para dispositivos móviles */
    }

    .rentabilidad-text {
      font-size: 12px; /* Ajusta el tamaño de fuente para dispositivos móviles */
    }

    .title-vivienda {
      font-size: 16px; /* Ajusta el tamaño de fuente para dispositivos móviles */
    }

    .btn-oportunities {
      margin-top: 2rem; /* Ajusta el margen superior para dispositivos móviles */
    }

    .about-us-text {
      font-size: 15px;
      margin-right: 1rem;
      margin-left: 1rem;
    }

    .images-team {
      flex-direction: column;
      align-items: center;
    }

    .image-team {
      width: 35%;
      margin-bottom: 1rem;
    }

    .image-container {
      width: 100%; /* The image will occupy the full width of the parent container */
      max-width: 500px; /* Set a maximum width as needed */
      height: 250px;
      margin: 0 auto; /* Center the container in the parent if it's wider than the content */
    }

    .review-card {
      flex: 0 0 60%;
    }

    .text-review {
      font-size: 16px;
    }

    .author-review {
      font-size: 15px;
    }
  }

  /* Otros media queries para ajustar más estilos en tamaños de pantalla diferentes */
  @media screen and (min-width: 320px) and (max-width: 481px) {

    h1 {
      font-size: 25px;
    }

    h3 {
      font-size: 18px;
      margin-right: .5rem;
      margin-left: .5rem;
    }

    .container-header {
      top: 110px;
      margin-right: 1rem;
      margin-left: 1rem;
    }

    .subtitle {
      font-size: 18px;
    }

    .re-subtitle {
      font-size: 20px;
    }

    .carousel {
      height: 60vh;
    }

    .number-about-us {
      font-size: 18px;
    }

    .about-us {
      margin-right: 2rem;
      margin-left: 2rem;
    }

    .oportunities {
      padding: 1rem 0;
      margin-bottom: 3rem;
    }

    .price {
      font-size: 14px;
    }

    .text-icon {
      margin-right: 1rem;
      margin-left: 1rem;
      margin-bottom: 1rem;
      font-size: 14px;
    }

    .services-text {
      flex-direction: column;
      align-items: center;
    }

    .containerInfo {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-content: center;
    }

    .infoExits {
      margin: 0;
    }
    
    .type {
      text-align: center;
      margin-bottom: 1rem;
    }

    .last-type {
      text-align: center;
    }

    .container-icon {
      margin-top: 10px;
    }

    .title-icon {
      font-size: 16px;
    }

    .oportunities-container {
      padding-right: 1rem;
      padding-left: 1rem;
    }

    .containerVivienda {
      flex-direction: column;
      align-items: center;
    }

    .vivienda-container {
      padding: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      width: 80%;
    }

    .image {
      height: auto; /* Ajusta la altura de la imagen para dispositivos móviles */
      max-width: 100%; /* Ajusta el ancho máximo de la imagen para dispositivos móviles */
      margin-bottom: 20px;
      border-radius: 5px;
    }

    .rentabilidad {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }

    .rentabilidad-container {
      display: flex;
      align-items: baseline;
    }

    .rentabilidad-number {
      font-size: 18px; /* Ajusta el tamaño de fuente para dispositivos móviles */
    }

    .rentabilidad-text {
      font-size: 12px; /* Ajusta el tamaño de fuente para dispositivos móviles */
    }

    .title-vivienda {
      margin-bottom: 10px;
      font-size: 15px; /* Ajusta el tamaño de fuente para dispositivos móviles */
    }

    .btn-oportunities {
      margin-top: 2rem; /* Ajusta el margen superior para dispositivos móviles */
    }

    .images-team {
      flex-direction: column;
      align-items: center;
    }

    .image-team {
      width: 50%;
      margin-bottom: 1rem;
    }

    .image-container {
      width: 100%; /* The image will occupy the full width of the parent container */
      max-width: 500px; /* Set a maximum width as needed */
      height: 250px;
      margin: 0 auto; /* Center the container in the parent if it's wider than the content */
    }

    .review-card {
      flex: 0 0 80%;
    }

    .text-review {
      font-size: 15px;
    }

    .author-review {
      font-size: 14px;
    }
  }

    /** Media queries para opciones en concreto en la distruibución */
  @media screen and (max-width: 370px) {
    .rentabilidad {
      flex-direction: column;
      align-items: center;
    }

    .rentabilidad-container {
      margin-bottom: .5rem;
    }

    .image {
      margin-bottom: 10px;
    }

    .title-vivienda {
      font-size: 18px;
      text-align: center;
      margin-top: 1rem;
    }

    .vivienda-container {
      padding: 15px;
      padding-top: 15px;
      padding-bottom: 15px;
      width: 100%;
    }

    .review-card {
      flex: 0 0 90%;
    }

    .text-review {
      font-size: 15px;
    }

    .author-review {
      font-size: 14px;
    }
  }

</style>