<template>
  <header class="header">
    <div class="header-content">
      <nav class="navbar">
        <div class="logo">
          <img src="/assets/logo.png" alt="Logo" class="logo-img">
          <p>Investments Real State JJP</p>
        </div>
        <div class="menu-icon" @click="toggleMenu" v-if="!isMenuOpen">
          <i class="fa-solid fa-bars"></i>
        </div>
        <div class="close-icon" @click="toggleMenu" v-else>
          <i class="fa-solid fa-xmark"></i>
        </div>
        <ul class="nav-links" :class="{ 'active': isMenuOpen }">
          <li @click="scrollToSection('start')"><a href="/#Inicio">Inicio</a></li>
          <li @click="scrollToSection('section1')"><a href="/#Servicio">Servicio integral</a></li>
          <li @click="scrollToSection('section2')"><a href="/#Exitos">Éxitos</a></li>
          <li @click="scrollToSection('section3')"><a href="/#Nosotros">Quien somos</a></li>
          <li @click="toggleMenu"><router-link to="/ContactForm">Contacto</router-link></li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      isMenuOpen: false
    };
  },
  methods: {
    toggleMenu() {
      if (window.innerWidth <= 1150) {
        this.isMenuOpen = !this.isMenuOpen;
      }
    },
    navigateToSection(sectionId) {
      this.$emit('navigateToSection', sectionId);
    },
  }
};
</script>
  
  <style scoped>
  .header {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transition: all ease .3s;
    color: #0A0B3B;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04, .1);
    width: 100%;
    position: fixed;
    margin: 0 auto;
    background-color: white;
    z-index: 1000;
    border-radius: 5px;
  }

  .menu-icon {
    display: none;
  }
  
  .navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    padding-right: 180px;
    padding-left: 180px;
  }

  p {
    font-size: 17px;
    font-weight: 500;
    color: #0A0B3B;
    font-family: 'Kufam';
  }

  .logo {
    display: flex;
    align-items: center;
  }

  .logo-img {
    width: 40px; /* Ajusta el ancho según tus necesidades */
    margin-right: 10px; /* Espaciado entre el logo y el texto */
  }
  
  .nav-links {
    list-style: none;
    display: flex;
    gap: 1rem;
  }
  
  .nav-links li {
    margin: 0;
    margin-right: 15px;
  }

  .nav-links li:hover {
    font-weight: 500;
    transition: all ease .3s;
  }
  
  .nav-links a {
    color: #0A0B3B;
    text-decoration: none;
    font-family: 'Kufam';
    font-size: 16px;
  }

  /* Para dispositivos con pantalla estándar */
  @media screen and (min-width: 769px) and (max-width: 1150px) {
  .header {
    width: 100%; /* Cambia el ancho a 100% para pantallas más pequeñas */
  }

  .navbar {
    padding-bottom: 0.3rem;
    padding-top: 0.3rem;
    padding-right: 40px;
    padding-left: 40px;
  }

  .logo {
    margin-top: .7rem;
    margin-bottom: .6rem;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    background-color: white;
    border-radius: 5px;
    top: 80px;
    left: 0;  
    padding: 1rem;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }

  .nav-links.active {
    display: flex;
  }

  .nav-links li {
    margin: 0.5rem 0;
  }

  .menu-icon {
    display: flex;
  }
}

/* Media query para tamaños de pantalla pequeños */
@media screen and (max-width: 769px) {
  .header {
    width: 100%; /* Cambia el ancho a 100% para pantallas más pequeñas */
  }

  .navbar {
    padding-bottom: 0.3rem;
    padding-top: 0.3rem;
    padding-right: 40px;
    padding-left: 40px;
  }

  .logo {
    margin-top: .7rem;
    margin-bottom: .6rem;
  }
  
  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    background-color: white;
    border-radius: 5px;
    top: 80px;
    left: 0;
    padding: 1rem;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }

  .nav-links.active {
    display: flex;
  }

  .nav-links li {
    margin: 0.5rem 0;
  }

  .menu-icon {
    display: flex;
  }
}

/* Otros media queries para ajustar más estilos en tamaños de pantalla diferentes */
@media screen and (min-width: 320px) and (max-width: 481px) {
  .header {
    height: 60px;
  }

  p {
    font-size: 16px;
  }

  .navbar {
    padding-bottom: 0.3rem;
    padding-top: 0.3rem;
    padding-right: 20px;
    padding-left: 20px;
  }

  .logo {
    font-size: 1.2rem;
  }

  .nav-links {
    gap: 0.5rem;
  }

  .navbar {
    background-color: white;
  }
}

  </style>
  