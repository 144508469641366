<template>
    <footer class="footer">
      <div class="footer-content">
        <div class="social-media">
          <div class="logo">
            <img src="/assets/logo.png" alt="Logo" class="logo-img">
            <h4 class="title">Investments Real State JJP</h4>
          </div>
          <!-- <div class="social-links">
            <a href="#"><i class="fab fa-instagram"></i></a>
            <a href="#"><i class="fa-brands fa-facebook"></i></a>
            <a href="#"><i class="fab fa-twitter"></i></a>
            <a href="#"><i class="fa-brands fa-linkedin"></i></a>
            <a href="#"><i class="fa-brands fa-youtube"></i></a>
          </div> -->
        </div>
        <div class="separator"></div>
        <div class="divider"></div>
        <div class="links">
          <h4>Navegación</h4>
          <a href="/#Inicio">Inicio</a>
          <a href="/#Servicio">Servicio integral</a>
          <a href="/#Exitos">Éxitos</a>
          <a href="/#Nosotros">Quien somos</a>
        </div>
        <div class="links">
          <h4>Recursos</h4>
          <router-link to="/ContactForm">Contacto</router-link>
          <router-link to="/Legal">Aviso legal</router-link>
          <router-link to="/PrivacyPolicy">Política de privacidad</router-link>
        </div>
        <div class="separator"></div>
        <div class="divider"></div>
        <div class="contact">
          <h4>Contacto</h4>
          <a href="#"><i class="fa-solid fa-envelope"></i>ester.guerra@jjpinvestments.com</a>
          <a href="#"><i class="fa-solid fa-phone"></i>+34 666 807 004</a>
        </div>
      </div>
      <div class="copyright">
      Copyright © {{ currentYear }} Investments Real State
    </div>
    </footer>
  </template>

  <script>
  export default {
    data() {
      return {
        currentYear: new Date().getFullYear(),
      }
    }
  };
  </script>
  
  <style scoped>

  .footer .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: top;
    left: 50%;
    margin: 0 auto;
    width: 70%;
    height: 100%;
    padding: 1rem;
    border-bottom: 1px solid rgba(228, 228, 228, .5);
    border-top: 1px solid rgba(228, 228, 228, .5);
    color: #2c3e50;
  }

  h4 {
    color: #2c3e50;
    text-align: start;
    font-size: 15px;
    margin: 0;
    margin-bottom: 1rem;
    font-family: 'Kufam';
  }

  .title {
    color: #2c3e50;
    text-align: start;
    margin: 0;
    font-family: 'Kufam';
  }

  .logo {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  .logo-img {
    width: 30px; /* Ajusta el ancho según tus necesidades */
    margin-right: 10px; /* Espaciado entre el logo y el texto */
  }

  .divider {
    border-right: 1px solid rgba(228, 228, 228, 0.5); /* Añade una línea vertical entre las secciones */
    margin: 0 1rem; /* Espaciado entre las secciones */
  }

  .separator {
    width: 100%;
    height: 1px;
    background-color: rgba(228, 228, 228, 0.5);
    margin-top: 1rem;
    margin-bottom: .5rem;
    display: none;
  }

  .social-media {
    text-align: left;
  }

  .social-links {
    display: inline-block;
  }

  .footer-content .social-media a {
    margin-right: 1rem;
    color: #3B3A5D;
  }

  .social-media a:last-child {
    margin-right: 0; /* Elimina el margen derecho del último enlace */
  }

  .footer-content .links {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .footer-content .links a {
    margin-bottom: .2rem;
    font-size: 14px;
  }

  .footer-content .contact {
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: #FFBB50;
    border-radius: 5px;
    margin: 0;
    padding: 10px;
    padding-right: 5rem;
  }

  .footer-content .contact i {
    margin-right: .5rem;
    color: #3B3A5D;
  }

  .footer-content .contact a {
    color: #3B3A5D;
    margin-bottom: 1rem;
    font-family: 'Kufam';
  }

  .footer-content .contact a:last-child  {
    margin-bottom: 0;
  }

  .footer .copyright {
    margin-top: 1rem;
    text-align: center;
    font-size: 12px;
    font-family: 'Kufam';
    color: #8D8D8D;
    margin-bottom: 2rem;
  }

  .footer-content a {
    color: #B16642;
    font-size: 14px;
    text-decoration: none;
    font-family: 'Kufam';
    transition: transform 0.3s ease; 
  }

  .footer-content a:hover {
    transition: transform 0.3s ease; 
    transform: scale(1.1); 
  }

  i {
    transition: transform 0.3s ease; 
  }

  i:hover {
    transition: transform 0.3s ease; 
    transform: scale(1.5); 
  }

  /* Media query para tamaños de pantalla pequeños */
  @media screen and (min-width: 320px) and (max-width: 1000px) {
    .footer .footer-content {
    /* Cambia los estilos para la versión responsiva aquí */
    text-align: center;
    flex-direction: column; /* Apila los elementos en vez de en línea */
    align-items: center; /* Centra los elementos en el eje cruzado */
  }

  h4 {
    text-align: center;
  }

  .social-media {
    text-align: center;
  }
  
  .footer .footer-content .divider {
    display: none; /* Oculta las divisiones en la versión responsiva */
  }

  .footer .footer-content .links,
  .footer .footer-content {
    text-align: center;
    margin-top: 1rem; /* Añade espacio entre las secciones */
  }

  .footer-content .contact {
    text-align: center;
    margin-top: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .separator {
    display: block;
  }

}

</style>  